<template>
  <!-- 路内停车场 —— 查看停车记录 -->
  <div class="ParkingInformation">
    <div class="box-card">
      <!-- 主要内容 -->
      <div>
        <!-- <el-button  class="checkButton" size="small" @click="defaultForm">返回</el-button> -->
        <div class="title">基本信息</div>
        <el-divider></el-divider>
        <el-form class="detailForm" label-width="150px" :model="dataDetail">
          <el-row :gutter="20">
            <el-col :span="16" style="text-align: left">
              <el-form-item label="车牌号：">{{
                dataDetail.plate_number
                ? dataDetail.plate_number === "_无_"
                  ? "无牌车"
                  : dataDetail.plate_number
                : "暂未录入车牌"
              }}
              <span  v-if="!dataDetail.go_out_time || validDateTime(dataDetail.go_out_time) === '/'" class="changeBrand">
                <i class="icon iconfont lebo-bianji"  @click="dialogFormVisible = true"></i>
              </span>
                <!-- <el-button size="small" type="danger" class="checkButton2" @click="dialogFormVisible = true"
                  v-if="!dataDetail.go_out_time || validDateTime(dataDetail.go_out_time) === '/'">车牌矫正</el-button> -->
              </el-form-item>
              <el-form-item label="停车场：">{{
                dataDetail.parking_name
              }}</el-form-item>
              <el-form-item label="入口：" v-if="isRoutW">{{ dataDetail.entryname }}
              </el-form-item>
              <el-form-item label="入场时间：">{{
                validDateTime(dataDetail.go_in_time)
              }}</el-form-item>
              <el-form-item label="停车时长：" v-if="dataDetail.go_out_time &&
                  validDateTime(dataDetail.go_out_time) != '/'
                  ">
                {{ getTimeText(dataDetail.go_in_time, dataDetail.go_out_time) }}
              </el-form-item>
              <el-form-item label="" style="color: #fff" v-if="isRoutW">0</el-form-item>
              <el-form-item label="类型：">
                {{
                  getWayName("CarOwnerCardType", dataDetail.car_owner_card_type)
                }}</el-form-item>
              <el-form-item label="出口：" v-if="isRoutW">{{ dataDetail.exportname }}
              </el-form-item>
              <el-form-item label="车位编号：" v-else>{{
                dataDetail.parking_space_name
              }}</el-form-item>
              <el-form-item v-if="dataDetail.go_out_time != '0001-01-01T00:00:00+08:05'" label="出场时间：">
                {{
                  dataDetail.go_out_time
                  ? validDateTime(dataDetail.go_out_time)
                  : "未出场"
                }}
              </el-form-item>
            </el-col>
            <el-col :span="8" style="text-align: left">
              <el-carousel :autoplay="false" style=" height: 200px" v-if="formatImage(dataDetail.go_in_image).length > 1">
                <el-carousel-item v-for="(item, index) in formatImage(dataDetail.go_in_image)" :key="index">
                  <el-image class="in-out-image" :src="item" :preview-src-list="formatImage(dataDetail.go_in_image)" fit="cover"
                    :z-index="9999">
                    <div slot="error" class="image-slot">
                      <i class="iconfont lebo-picerr"></i>
                      <span>加载失败</span>
                    </div>
                  </el-image>
                </el-carousel-item>
              </el-carousel>
              <div v-else-if="formatImage(dataDetail.go_in_image).length === 1">
                <el-image class="in-out-image" fit="cover" v-for="(item, index) in formatImage(dataDetail.go_in_image)"
                  :key="index" :src="item" :preview-src-list="formatImage(dataDetail.go_in_image)" :z-index="9999">
                  <div slot="error" class="image-slot">
                    <i class="iconfont lebo-picerr"></i>
                    <span>加载失败</span>
                  </div>
                </el-image>
              </div>
              <div v-else class="image-slot">
                <span>暂无入场图片</span>
              </div>
              <div class="maskBox">入场图片</div>
              <el-carousel :autoplay="false" style="height: 200px" v-if="formatImage(dataDetail.go_out_image).length > 1">
                <el-carousel-item v-for="(item, index) in formatImage(dataDetail.go_out_image)" :key="index">
                  <el-image class="in-out-image" :src="item" :preview-src-list="formatImage(dataDetail.go_out_image)"
                    fit="cover" :z-index="9999">
                    <div slot="error" class="image-slot">
                      <i class="iconfont lebo-picerr"></i>
                      <span>加载失败</span>
                    </div>
                  </el-image>
                </el-carousel-item>
              </el-carousel>
              <div v-else-if="formatImage(dataDetail.go_out_image).length === 1">
                <el-image class="in-out-image" fit="cover" v-for="(item, index) in formatImage(dataDetail.go_out_image)"
                  :key="index" :src="item" :preview-src-list="formatImage(dataDetail.go_out_image)" :z-index="9000">
                  <div slot="error" class="image-slot">
                    <i class="iconfont lebo-picerr"></i>
                    <span>加载失败</span>
                  </div>
                </el-image>
              </div>
              <div v-else class="image-slot">
                <span>{{
                  dataDetail.go_out_time === "0001-01-01T00:00:00+08:05"
                  ? "车辆暂未出场"
                  : "暂无出场图片"
                }}</span>
              </div>
              <div class="maskBox">出场图片</div>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <lebo-dialog append-to-body title="车牌矫正" :isShow="dialogFormVisible" footerSlot @close="dialogFormVisible = false"
      width="500px">
      <el-form ref="ruleFrom" :model="queryForm" :rules="rules">
        <el-form-item label="原车牌号：" :label-width="formLabelWidth">
          <div class="form-in-item">
            <el-input style="width: 200px" :disabled="true" :value="dataDetail.plate_number"
              autocomplete="off"></el-input>
          </div>
        </el-form-item>
        <el-form-item label="更改车牌号：" prop="plate_number" :label-width="formLabelWidth">
          <div class="form-in-item">
            <el-input style="width: 200px" maxlength="8" v-model="queryForm.plate_number" autocomplete="off"></el-input>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <lbButton type="goBack" icon="back" @click="dialogFormVisible = false">返 回</lbButton>
        <lbButton type="orign" icon="confirm" @click="licenseCorrect">保 存</lbButton>
      </div>
    </lebo-dialog>

  </div>
</template>

<script>
import { recordsDetail, plateCorrect } from '@/api/administrative'
import { mapMutations, mapGetters, mapState } from 'vuex'
export default {
  props: ['id'],
  data () {
    return {
      // 查询表单
      queryForm: {
        _id: '',
        ParkType: 1,
        plate_number: '',
        parking_space_id: ''
      },
      rules: {
        plate_number: [
          { required: true, message: '请输入车牌号', trigger: 'blur' },
          {
            pattern:
              /^([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}(([0-9]{5}[DF])|([DF]([A-HJ-NP-Z0-9])[0-9]{4})))|([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳]{1})$/,
            message: '常规格式：湘A12345'
          }
        ]
      },
      dataDetail: {},
      dialogFormVisible: false,
      formLabelWidth: '120px',
      record_id: ''
    }
  },
  created () {

  },
  mounted () {
    if (!this.record_id) {
      this.record_id = this.id
    }
    this.requestDetails({ record_id: this.record_id })
    this.queryForm.ParkType = this.isRoutW ? 2 : 1
  },
  computed: {
    ...mapGetters(['getOptions', 'getWayName']),
    ...mapState('menuList', ['isRoutW'])
  },
  methods: {
    ...mapMutations(['setQueryStatus']),
    async requestDetails (params) {
      console.log('params', params)
      try {
        const res = await recordsDetail(params)
        this.dataDetail = res.Data
        this.queryForm._id = this.dataDetail._id
        this.queryForm.parking_space_id = this.dataDetail.parking_space_id
      } catch (error) { }
    },
    // 车牌矫正
    licenseCorrect () {
      var that = this
      this.$refs.ruleFrom.validate(async (valid) => {
        if (valid) {
          console.log('this.queryForm', this.queryForm)
          await plateCorrect(this.queryForm)
          that.dialogFormVisible = false
          that.queryForm.plate_number = ''
          const id = that.id
          that.requestDetails({ record_id: id })
        } else {
          return false
        }
      })
    },
    // 返回
    // goBack () {
    //   this.setQueryStatus(2)
    //   this.$router.go(-1)
    // },
    defaultForm () {
      this.$emit('closeDialog', false)
    }
  }
}
</script>

<style scoped lang="less">
.title {
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  color: #000;
}

.detailForm {
  .maskBox {
    width: 100%;
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: white;
    text-align: center;
    background-color: rgba(0, 0, 0, .5);
    margin-bottom: 10px;
  }

  /deep/ .el-form-item {
    margin-bottom: 0px;
  }

  .el-form-item__content {
    text-align: left;
  }
  .changeBrand{
    color: #E39F27;
    margin-left: 20px;
    cursor: pointer;
  }
}

// .el-input__inner {
//   height: 36px;
//   line-height: 36px;
// }
.button_box {
  display: flex;
}

.button_box .el-button {
  margin-left: 15px;
  width: auto;
}

.el-dialog__body {
  padding: 20px;
}

.el-button {
  width: auto !important;
}

.imagefu img {
  width: 100%;
  height: auto;
  max-width: 100%;
  // max-height: 100%;
}

.itemone {
  display: flex;
  justify-content: flex-end;
}

.itemtwo {
  display: flex;
  justify-content: flex-start;
}

.form-in-item {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: start;
}

.in-out-image {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;

  // background-color: #ccc;
  /deep/ .image-slot {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #f5f7fa;
    color: #999;
    .iconfont{
      font-size: 36px;
    }
  }

  /deep/ .el-image__inner {
    max-width: 100%;
    // max-height: 100%;
    width: auto;
    height: auto;
  }
}

.image-slot {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #f5f7fa;
  color: #999;
  .iconfont{
      font-size: 36px;
    }
}

.el-carousel {
  height: 200px;
  width: 100%;

  /deep/ .el-carousel__container {
    position: relative;
    height: 200px;
  }

  /deep/ .el-carousel__button {
    background-color: #027dfc !important;
  }

  /deep/ .el-carousel__indicator--horizontal {
    padding: 4px 4px;
  }
}

.box-card {
  position: relative;

  .checkButton {
    position: absolute;
    right: 50px;
  }

  .checkButton2 {
    position: absolute;
    left: 150px;
    border: 1px solid black;
    // right: 150px;
  }
}
</style>
