<template>
  <!-- 路内停车场 —— 停车记录 -->
  <div class="box-card" style="text-align: left">
    <!-- 查询表单 -->
    <lebo-query :isMultiRow="true" @toggleChange="toggleChange" class="queryBox">
      <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
        @submit.native.prevent>
        <el-row>
          <el-col :span="24">
            <el-form-item label="车牌号：">
              <el-autocomplete :trigger-on-focus="false" popper-class="my-select" v-model="queryForm.plate_number_head"
                :fetch-suggestions="querySearch" placeholder="湘A" @select="handleSelect">
                <i class="el-icon-circle-close el-input__icon" slot="suffix" v-if="queryForm.plate_number_head"
                  @click="handleIconClick">
                </i>
                <template slot-scope="{ item }">
                  <div class="name">{{ item.name }}</div>
                </template>
              </el-autocomplete>
              <el-input maxlength="6" class="paltetail" v-model="queryForm.plate_number_tail" placeholder="BXXXX" @input="(e) => (queryForm.plate_number_tail = validPlatetoUpperCase(e))
                " @keyup.enter.native="onSubmit()"></el-input>
            </el-form-item>
            <el-form-item label="入场时间：">
              <el-date-picker v-model="go_in_timeduan" value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']" @blur="onSubmit" type="datetimerange" range-separator="至"
                start-placeholder="请选择开始日期" end-placeholder="请选择结束日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="出场时间：">
              <el-date-picker size="small" v-model="go_out_timeduan" value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']" @blur="onSubmit" type="datetimerange" range-separator="至"
                start-placeholder="请选择开始日期" end-placeholder="请选择结束日期">
              </el-date-picker>
            </el-form-item>

            <el-form-item v-if="!isMultiRow">
              <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="isMultiRow">
          <el-col :span="24">
            <el-form-item label="停车场：">
              <el-select popper-class="my-select" clearable v-model="queryForm.parking_id" filterable default-first-option
                placeholder="请选择停车场" @change="onSubmit()">
                <el-option v-for="item in getAllParkingList(1)" :key="item.parking_id" :label="item.parking_name"
                  :value="item.parking_id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="来源：">
              <el-select popper-class="my-select" clearable v-model="queryForm.exitTypeInPark" placeholder="请选择记录来源"
                @clear="queryForm.exitTypeInPark = null" @change="onSubmit()">
                <el-option v-for="item in getOptions('ExitTypeInPark')" :key="item.value" :label="item.name"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="车位编号：">
              <el-input maxlength="15" placeholder="请输入车位编号" v-model="queryForm.parking_space_name"
                @input="(e) => (queryForm.parking_space_name = validSpace(e))"
                @keyup.enter.native="onSubmit()"></el-input>
            </el-form-item>
            <el-form-item label="类型：">
              <el-select popper-class="my-select" clearable v-model="queryForm.card_type" placeholder="请选择卡片类型"
                @clear="queryForm.card_type = null" @change="onSubmit()">
                <el-option v-for="item in getOptions('CarOwnerCardType', true)" :key="item.value" :label="item.name"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="出入场状态：">
              <el-select popper-class="my-select" clearable v-model="queryForm.carstate" placeholder="请选择出入场状态"
                @clear="queryForm.carstate = null" @change="onSubmit()">
                <!-- 0. 请选择 ，1.在场，2.离场 -->
                <el-option label="在场" :value="1"></el-option>
                <el-option label="离场" :value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="isMultiRow">
              <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </lebo-query>
    <le-card title="停车记录">
      <template slot="heard-right">
        <div>
          <lbButton type="warning" icon="daochu" @click="pullFile" v-preventReClick
            v-if='isShowBtn("1822f20341084186969c1e64")'>导出</lbButton>
        </div>
      </template>
      <!-- 主要内容 -->
      <div>
        <!-- 表格 -->
          <!-- 使用插件生产表格 -->
          <!-- <avue-crud :data="tableData" :option="option" :page.sync="page"
            @size-change="sizeChange" @current-change="currentChange">
              <template slot-scope="{row}" slot="plate_number">
                <span>{{ row.plate_number ? row.plate_number : '暂未录入车牌' }}</span>
            </template>
             <template slot-scope="{row}" slot="car_owner_card_type">
              {{ getWayName('CarOwnerCardType',row.car_owner_card_type) }}
            </template>
            <template slot-scope="{row}" slot="go_in_time">
              <span>{{ validDateTime(row.go_in_time) }}</span>
            </template>
            <template slot-scope="{row}" slot="go_out_time">
              <span>{{ validDateTime(row.go_out_time) }}</span>
            </template>
            <template slot-scope="{row}" slot="exit_type">
              <span>{{ getWayName("ExitTypeInPark", row.exit_type) }} </span>
            </template>
            <template slot-scope="{row}" slot="menu">
              <lbButton type="succes" icon="chakan" hint="查看" @click="lookParking(row)"></lbButton>
            </template>
          </avue-crud> -->
        <el-table class="tablestyle" ref="multipleTable" :data="tableData" align="center" header-align="center"
          tooltip-effect="dark" border stripe>
          <el-table-column label="车牌号">
            <template slot-scope="scope">
              <span>{{ scope.row.plate_number ? scope.row.plate_number : '暂未录入车牌' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="parking_name" label="停车场"></el-table-column>
          <el-table-column label="类型">
            <template slot-scope="scope">
              {{ getWayName('CarOwnerCardType', scope.row.car_owner_card_type) }}
            </template>
          </el-table-column>
          <el-table-column prop="parking_space_name" label="车位编号"></el-table-column>
          <el-table-column label="入场时间">
            <template slot-scope="scope">
              <span>{{ validDateTime(scope.row.go_in_time) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="出场时间">
            <template slot-scope="scope">
              <span>{{ validDateTime(scope.row.go_out_time) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="来源">
            <template slot-scope="scope">
              <span>{{ getWayName("ExitTypeInPark", scope.row.exit_type) }} </span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="120">
            <template slot-scope="scope">
              <lbButton type="succes" icon="chakan" hint="查看" @click="lookParking(scope.row)"></lbButton>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="TotalCount" >
        </el-pagination>
      </div>
    </le-card>

    <!-- 导出时间选择对话框 -->
    <lebo-dialog title="导出记录" :isShow="derivedSelection" width="560px" class="derivedSelectionDialog" :footerSlot="true"
      @close="derivedSelection = false">
      时间： <el-date-picker v-model="derivedmonth" type="month" placeholder="选择月">
      </el-date-picker>
      <div slot="footer" class="dialog-footer">
        <!-- <lbButton type="goBack" icon="back" @click="derivedSelection = false">返 回</lbButton> -->
        <lbButton type="default" fill icon="confirm" @click="submissionDate">导 出</lbButton>
      </div>
    </lebo-dialog>
    <!-- 查看停车记录 -->
    <lebo-dialog :isShow="showSearchRecord" width="60%" title="查看停车记录" :footerSlot="true"
      @close="closeShowRecord" closeOnClickModal>
      <searchRecordPage v-if="showSearchRecord" :id="dialogId" @closeDialog="closeSearch"></searchRecordPage>
    </lebo-dialog>
  </div>
</template>

<script>
import { accessRecords } from '@/api/administrative'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import plateNumberHeadList from '@/utils/plate_number_head'
import searchRecordPage from './components/searchrecordpage/index.vue'
export default {
  components: { searchRecordPage },
  data () {
    return {
      go_in_timeduan: '',
      go_out_timeduan: '',
      plateNumberHeadList: plateNumberHeadList,
      // 查询表单
      queryForm: {
        PageSize: 10,
        PageIndex: 1,
        parking_id: '',
        parking_space_name: '', // 车位编号
        plate_number_head: '',
        plate_number_tail: '',
        card_type: null,
        go_in_time_start: '',
        go_in_time_end: '',
        go_out_time_start: '',
        go_out_time_end: '',
        carstate: null,
        exitTypeInPark: null,
        ParkType: 1
      },
      // 表格数据
      tableData: [],
      TotalCount: null,
      derivedSelection: false,
      derivedmonth: '', // 导出日期选择
      beginQueryTime: '', // 导出开始时间
      endQueryTime: '', // 导出结束时间
      showSearchRecord: false, // 查看停车记录弹框
      dialogId: '', // 停车记录id
      isMultiRow: true, // 查询下拉框
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '车牌号',
            prop: 'plate_number',
            slot: true
          }, {
            label: '停车场',
            prop: 'parking_name'
          }, {
            label: '类型',
            prop: 'car_owner_card_type',
            slot: true
          }, {
            label: '车位编号',
            prop: 'parking_space_name'
          }, {
            label: '入场时间',
            prop: 'go_in_time',
            slot: true
          }, {
            label: '出场时间',
            prop: 'go_out_time',
            slot: true
          }, {
            label: '来源',
            prop: 'exit_type',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  created () {
  },
  watch: {
    'go_in_timeduan' (newV, oldV) {
      if (newV) {
        this.queryForm.go_in_time_start = newV[0]
        this.queryForm.go_in_time_end = newV[1]
      } else {
        this.queryForm.go_in_time_start = ''
        this.queryForm.go_in_time_end = ''
        this.onSubmit()
      }
    },
    'go_out_timeduan' (newV, oldV) {
      if (newV) {
        this.queryForm.go_out_time_start = newV[0]
        this.queryForm.go_out_time_end = newV[1]
      } else {
        this.queryForm.go_out_time_start = ''
        this.queryForm.go_out_time_end = ''
        this.onSubmit()
      }
    }
  },
  activated () {
    console.log('activatedactivated')
  },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapState('menuList', ['isRoutW']),
    ...mapGetters(['getOptions', 'getWayName', 'getAllParkingList'])
  },
  mounted () {
    window.addEventListener('beforeunload', e => this.beforeunloadFn(e))
    if (this.queryStatus === 2) {
      this.queryForm = this.deepClone(this.queryObj)
      this.go_in_timeduan = [this.queryForm.go_in_time_start, this.queryForm.go_in_time_end]
      this.go_out_timeduan = [this.queryForm.go_out_time_start, this.queryForm.go_out_time_end]
    }
    this.requestPorkingList()
    this.requestResult(this.queryForm)
  },
  destroyed () {
    window.removeEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  methods: {
    ...mapMutations(['setQueryObj', 'setQueryStatus']),
    ...mapActions(['requestPorkingList']),
    beforeunloadFn (e) {
      this.setQueryStatus(1)
    },
    // 查询
    onSubmit () {
      if (this.queryForm.card_type === '') {
        this.queryForm.card_type = null
      }
      this.queryForm.PageIndex = 1
      this.requestResult(this.queryForm)
    },
    querySearch (queryString, cb) {
      var restaurants = this.plateNumberHeadList
      // console.log(restaurants);
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants
      // 调用 callback 返回建议列表的数据
      // console.log('results----', results);
      if (results.length <= 0) this.queryForm.plate_number_head = ''
      cb(results)
    },
    createFilter (queryString) {
      return (restaurant) => {
        // console.log(restaurant);
        return (restaurant.name.toLowerCase().indexOf(queryString.toLowerCase()) !== -1)
      }
    },
    handleSelect (item) {
      // console.log('item----', item);
      this.queryForm.plate_number_head = item.value
      this.onSubmit()
    },
    handleIconClick (ev) {
      // console.log(ev);
      this.queryForm.plate_number_head = ''
      this.onSubmit()
    },
    // 停车场列表
    // 记录查询
    async requestResult (params) {
      var obj = this.deepClone(params)
      if (obj.card_type === null || obj.card_type === '') {
        obj.card_type = -1
      }
      if (obj.carstate === null || obj.carstate === '') {
        obj.carstate = 0
      }
      if (obj.exitTypeInPark === null || obj.exitTypeInPark === '') {
        obj.exitTypeInPark = 0
      }
      try {
        const res = await accessRecords(obj)
        this.tableData = res.Data.DataList
        this.TotalCount = res.Data.TotalCount
        this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
      } catch (error) { }
    },
    // 导出
    pullFile () {
      this.derivedSelection = true
      this.derivedmonth = new Date(Date.now())
    },
    // 导出
    exportFile (tableData) {
      if (tableData.length <= 0) {
        this.$msg.warning('暂无数据！')
      } else {
        // 设置当前日期
        var name = this.$moment(new Date()).format('YYYY-MM-DD')
        // console.log(name);
        import('@/utils/Export2Excel').then((excel) => {
          const tHeader = [
            '车牌号',
            '停车场',
            '卡片类型',
            '车位编号',
            '入场时间',
            '出场时间',
            '设备类型'
          ]
          const filterVal = [
            'plate_number',
            'parking_name',
            'car_owner_card_type',
            'parking_space_name',
            'go_in_time',
            'go_out_time',
            'exit_type'
          ]
          const list = tableData
          const data = this.formatJson(filterVal, list)
          // console.log(data);
          excel.export_json_to_excel({
            header: tHeader, //  必填   导出数据的表头
            data, //  必填   导出具体数据
            filename: '路内停车场停车记录' + name, //  非必填   导出文件名
            autoWidth: true, //  非必填   单元格是否自动适应宽度
            bookType: 'xlsx' //  非必填   导出文件类型
          })
        })
      }
    },
    // 选择导出日期确定触发
    async submissionDate () {
      const selectYear = this.derivedmonth.getFullYear()
      const selectMonth = (this.derivedmonth.getMonth() + 1 < 10 ? '0' + (this.derivedmonth.getMonth() + 1) : this.derivedmonth.getMonth() + 1)
      this.beginQueryTime = selectYear + '-' + selectMonth + '-01 00:00:00'
      if (selectMonth === '01' || selectMonth === '03' || selectMonth === '05' || selectMonth === '07' || selectMonth === '08' || selectMonth === '10' || selectMonth === '12') {
        this.endQueryTime = selectYear + '-' + selectMonth + '-31 23:59:59'
      } else if (selectMonth === '04' || selectMonth === '06' || selectMonth === '09' || selectMonth === '11') {
        this.endQueryTime = selectYear + '-' + selectMonth + '-30 23:59:59'
      } else if (selectMonth === '02' && selectYear % 4 === 0) {
        this.endQueryTime = selectYear + '-' + selectMonth + '-29 23:59:59'
      } else {
        this.endQueryTime = selectYear + '-' + selectMonth + '-28 23:59:59'
      }
      const params = {
        parking_id: this.queryForm.parking_id,
        parking_space_name: this.queryForm.parking_space_name, // 车位编号
        plate_number_head: this.queryForm.plate_number_head,
        plate_number_tail: this.queryForm.plate_number_tail,
        card_type: this.queryForm.card_type ? this.queryForm.card_type : -1,
        // go_in_time_start: this.queryForm.go_in_time_start,
        go_in_time_start: this.beginQueryTime, // 导出开始时间
        go_in_time_end: this.endQueryTime,
        go_out_time_start: this.queryForm.go_out_time_start,
        go_out_time_end: this.queryForm.go_out_time_end,
        carstate: this.queryForm.carstate ? this.queryForm.carstate : 0,
        exitTypeInPark: this.queryForm.exitTypeInPark ? this.queryForm.exitTypeInPark : 0,
        ParkType: 1
      }
      const res = await accessRecords(params)
      if (res && res.Code === 200) {
        this.exportFile(res.Data.DataList)
      }
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          var map = {}
          this.$set(map, j, v[j])
          return this.formatter(map, { property: j })
          // 为formatter专门为了码表渲染的组件
        })
      )
    },
    // 数据格式化
    formatter (row, column) {
      // console.log(row);
      // console.log(column);
      switch (column.property) {
        case 'go_in_time':
          return this.$moment(row.go_in_time).format('YYYY-MM-DD HH:mm:ss')
        case 'go_out_time':
          // console.log(row.go_out_time);
          return this.validDateTime(row.go_out_time)
        case 'car_owner_card_type':
          return this.getWayName('CarOwnerCardType', row.car_owner_card_type)
        // return this.card_type(row.car_owner_card_type);
        case 'exit_type':
          return this.getWayName('ExitTypeInPark', row.exit_type)
        default:
          return row[column.property]
      }
    },
    // 每页条数改变
    handleSizeChange (size) {
      this.queryForm.PageSize = size
      this.requestResult(this.queryForm)
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.requestResult(this.queryForm)
    // },
    // 页码改变
    handleCurrentChange (page) {
      this.queryForm.PageIndex = page
      this.requestResult(this.queryForm)
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.requestResult(this.queryForm)
    // },
    // 查看
    lookParking (row) {
      this.showSearchRecord = true
      this.dialogId = row._id
      // var preStr = this.isRoutW ? 'routw-' : ''
      // this.$router.push({
      //   path: `/${preStr}search-recordpage/${row._id}`
      // })
    },
    closeSearch () {
      this.showSearchRecord = false
      this.requestResult(this.queryForm)
    },
    // 下拉查询
    toggleChange (flag) {
      console.log(flag)
      this.isMultiRow = flag
    },
    // 关闭弹框
    closeShowRecord () {
      this.showSearchRecord = false
      this.requestResult(this.queryForm)
    }
  },
  beforeRouteLeave (to, from, next) {
    this.setQueryObj(this.queryForm)
    this.setQueryStatus(1)
    next()
  }
}
</script>

<style scoped lang="less">
.box-card {
  margin-top: 20px;
  .queryBox{
    margin-bottom: 20px;
  }
}

.query_form {
  /deep/ .el-autocomplete {
    width: 80px;

    .el-input.el-input--small {
      width: inherit !important;
    }
  }
}

.tablestyle {
  /deep/ .el-table__body-wrapper {
    max-height: 480px;
  }
}

.paltenumber {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.paltehead {
  position: relative;
  width: 80px;
  height: 40px;
  margin-right: 2px;

  .selectView {
    position: absolute;
    display: none;
    top: 40px;
    left: 0;
    z-index: 999;
    background-color: #409eff;
    flex-direction: column;
    cursor: pointer;

    .selectTop {
      display: flex;
      height: 30px;
      flex: 1;
      justify-content: space-between;
    }

    .horitan {
      display: flex;
      flex-direction: row;
      margin-bottom: 2px;

      .selectItem {
        border-color: #777;
        border-width: 1px;
        border-style: solid;
        display: flex;
        height: 30px;
        width: 30px;
        align-items: center;
        justify-content: center;
        margin-right: 2px;
        color: #fff;
        font-size: 12px;
      }
    }
  }
}

.paltetail {
  width: 200px;
  margin-left: 2px;
}

.grid-content {
  height: 50px;
  margin-bottom: 5px;
  background: #fff;
}

/deep/.el-dialog__body {
  text-align: center;
}
// 列表最大高度
/deep/.el-table .el-table__body-wrapper{
  max-height: 490px !important;
}
/deep/.el-form.query_form.el-form--inline .el-form-item.el-form-item--small .el-form-item__content .el-date-editor{
  width: 350px!important;
}
/deep/.el-form.query_form.el-form--inline .el-form-item.el-form-item--small .el-form-item__content .el-input.el-input--small{
  width: 180px;
}
</style>
